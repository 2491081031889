import { Injectable, Injector, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Location, DOCUMENT } from '@angular/common';
import { PlatformService } from './platform.service';
import { EnvironmentService } from './environment.service';
import { GlobalEventsService } from './global-events.service';
import { distinct } from 'rxjs/operators';
import { REQUEST } from 'src/express.tokens';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private _meta = inject(Meta);
  private _title = inject(Title);
  private _platform = inject(PlatformService);
  private _location = inject(Location);
  private _env = inject(EnvironmentService);
  private _globalEvents = inject(GlobalEventsService);
  private _injector = inject(Injector);
  private _document = inject<Document>(DOCUMENT);

  private _localesForHeader = ['en', 'de', 'en-ca', 'en-nz', 'en-au', 'fr-ca', 'en-eu', 'es', 'el', 'it'];

  private _mapPrefixMirrorsLoacales = {
    'en-au': '1',
  };

  /**
   * Returns hostname on which app running
   *
   * @private
   */
  private _getAppHost(): string | undefined {
    const host = this._platform.isBrowser ? window.location.host : this._injector.get(REQUEST).headers.host;
    return `https://${host}`;
  }

  /**
   * Set in head Title & description
   * @param tags
   */
  public setMetaTags(tags: any) {
    this._title.setTitle(tags.MetaTitle || 'All Star Casino');
    this._meta.updateTag({ name: 'description', content: tags.MetaDesc || '' });

    if ('NoIndex' in tags && tags.NoIndex) {
      this.addMetaRobotNoIndex();
    }
  }

  /**
   * Add new alternate/canonical links and remove old links
   */
  public updateGlobalHeadLink() {
    this._globalEvents.routerNavigationEnd$.pipe(distinct()).subscribe(e => {
      this._removeOldLinks(['alternate', 'canonical', 'x-default']);
      this._addHeadLinkAlternate();
      this._addHeadLinkCanonical('canonical');
      this._addHeadLincXDefault();
      this._addLangTagForHTML();
    });
  }

  /**
   * Add link alternate <link rel="alternate" href="https://www.allstarcasino.com/en" hreflang="en">
   */
  private _addHeadLinkAlternate() {
    this._localesForHeader.forEach(e => {
      const link: HTMLLinkElement = this._document.createElement('link');
      let url: any = this._location.path().split('/');
      url[1] = e;
      url = url.join('/');
      link.setAttribute('rel', 'alternate');
      if (e !== 'en') {
        if (this._mapPrefixMirrorsLoacales[e]) {
          link.setAttribute('href', this._overrideMirrorDomain(environment.app_host, this._mapPrefixMirrorsLoacales[e]) + url);
          link.setAttribute('hreflang', e);
        } else {
          link.setAttribute('href', environment.app_host + url);
          link.setAttribute('hreflang', e);
        }
      } else {
        link.setAttribute('href', environment.app_host + `/${url.split('/').slice(2).join('/')}`);
        link.setAttribute('hreflang', e);
      }
      this._document.head.appendChild(link);
    });
  }

  /**
   * Adds a canonical link element to the document head, pointing to the appropriate URL for the current page.
   * The URL is constructed based on the current URL path, ensuring that the canonical link points to the correct language version of the page.
   * This is important for SEO and helps search engines understand the relationship between different language versions of the same content.
   * @param attr The rel attribute value for the link element, typically 'canonical'.
   */
  private _addHeadLinkCanonical(attr: string) {
    const link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', attr);
    switch (true) {
      case this._location.path() === '/en':
        link.setAttribute('href', this._getAppHost() + this._location.path());
        break;
      case this._location.path().includes('/en/'):
        link.setAttribute('href', this._getAppHost() + '/' + this._location.path().replace('/en/', '/en'));
        break;
      case ['en-nz', 'en-ca', 'en-eu'].some(item => this._location.path().includes(item)):
        link.setAttribute('href', this._getAppHost() + this._location.path().replace('/en/', '/en'));
        break;
      case ['en-au'].some(item => this._location.path().includes(item)):
        link.setAttribute(
          'href',
          this._overrideMirrorDomain(environment.app_host, this._mapPrefixMirrorsLoacales['en-au']) + this._location.path(),
        );
        break;
      case !this._location.path():
        link.setAttribute('href', this._getAppHost());
        break;
      default:
        link.setAttribute('href', this._getAppHost() + this._location.path());
        break;
    }
    this._document.head.appendChild(link);
  }

  /**
   * Adds an 'x-default' link element to the document head, pointing to the canonical URL for the current page.
   * The URL is constructed by replacing any language-specific path segments (e.g. '/en/') with '/en'.
   * This ensures that the 'x-default' link points to the English version of the page, as per the recommended best practices for internationalization.
   */
  private _addHeadLincXDefault() {
    const link: HTMLLinkElement = this._document.createElement('link');
    link.setAttribute('rel', 'x-default');
    let path = this._location.path();
    for (const lang of this._localesForHeader) {
      path = path.replace(`/${lang}`, '/en');
    }
    link.setAttribute('href', this._getAppHost() + path);
    this._document.head.appendChild(link);
  }

  /**
   * Remove old alternate\canonical links from DOM
   * @param typeOfLinks
   */
  private _removeOldLinks(typeOfLinks: any[]) {
    if (this._platform.isBrowser) {
      typeOfLinks.forEach(e => {
        const oldLinks: NodeListOf<HTMLLinkElement> = this._document.querySelectorAll(`link[rel="${e}"]`);
        if (oldLinks.length) {
          oldLinks.forEach(j => {
            j.remove();
          });
        }
      });
    }
  }

  /**
   * Add in head <meta name="robots" content="noindex" />
   */
  public addMetaRobotNoIndex() {
    this._meta.addTags([{ name: 'robot', content: 'noindex' }]);
  }

  /**
   * Sets the `lang` attribute of the HTML `<html>` element based on the current URL path.
   * If the path contains a language-specific segment (e.g. `/en/`), the corresponding language code is used.
   * Otherwise, the default language code (`'en'`) is used.
   * This ensures that the correct language is specified for the page, which is important for accessibility and SEO.
   */
  private _addLangTagForHTML() {
    const defaultLocale = 'en';
    const matches = this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//)
      ? this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)\//)
      : this._location.path().match(/^\/([a-z]{2}(?:-[A-Z]{2})?)/);
    const locale = matches && this._env.env.languageList.map(e => e.short).indexOf(matches[1]) !== -1 ? matches[1] : defaultLocale;
    this._document.documentElement.lang = locale;
  }

  /**
   * Overrides the mirror domain by appending the provided mirror prefix to the second-to-last part of the domain.
   * @param domain The original domain to be overridden.
   * @param mirrorPrefix The prefix to be appended to the second-to-last part of the domain.
   * @returns The overridden domain with the mirror prefix applied.
   */
  private _overrideMirrorDomain(domain: string, mirrorPrefix: string) {
    const parts = domain.split('.');
    if (parts.length >= 2) {
      parts[parts.length - 2] += mirrorPrefix;
    }
    return parts.join('.');
  }
}
